import type { ChangeEvent, FC } from 'react'
import { Button, GridItem, HStack, SimpleGrid, VStack } from '@chakra-ui/react'
import { GrandText } from 'ui'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import type { FilterOptionDTO, SortingOption } from 'ecosystem'
import { commaSeparator } from 'shared-utils'
import type { ProductsState } from 'shared-redux/state'
import { overrideText } from 'ui/lib/overrides'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import { FilterType } from 'ecosystem'
import { useUIGlobalsContext } from 'ui/lib/ui-globals/hooks'
import useFilters from '../../../hooks/useFilters'
import FilterRangeSelector from '../../FilterRangeSelector'
import CheckboxFilterItem from '../../CheckboxFilterItem'

interface IFilterItemsProps {
  filter: FilterOptionDTO
  sortingOptions: SortingOption[]
  productsState: ProductsState
  sliderMaxPrice: number
}

export interface FilterItemsListVerticalOverrides {
  removeFilter?: string
  uniqueOption?: string
  notValuesFound?: string
}

const FilterItems: FC<IFilterItemsProps> = ({
  filter,
  sortingOptions,
  productsState,
  sliderMaxPrice
}) => {
  const overrides = useOverridesContext<keyof FilterItemsListVerticalOverrides>()
  const { priceMaximumFractionDigits } = useUIGlobalsContext()
  const {
    selectedNumericValues,
    handleSliders,
    handleRemoveSlider,
    filterType,
    handleCheckBoxes,
    selectedCheckboxes
  } = useFilters({
    filter,
    sortingOptions,
    productsState
  })

  const getItems = () => {
    const uniqueID = (value: string | number, index: number) => `${filter.name}-${value}-${index}`

    switch (filterType) {
      case FilterType.TEXT:
        return (
          <SimpleGrid
            columns={1}
            gap={{
              base: 1,
              md: 6
            }}
            width="100%"
            zIndex={9999}>
            {filter.textValues?.map((value, index) => (
              <Item
                key={uniqueID(value, index)}
                {...{ value, filter, handleCheckBoxes, selectedCheckboxes }}
              />
            ))}
          </SimpleGrid>
        )

      case FilterType.NUMBER: {
        const values = filter.numberValues?.map((v) => v) || []
        const range = [Math.min(...values), Math.max(...values)]
        const nv = selectedNumericValues.find((item) => item.id === filter.name)

        if (values.length >= 2) {
          return (
            <VStack w="full">
              <FilterRangeSelector
                currentMaxValue={commaSeparator(
                  nv?.range.length ? nv.range[1] : range[1],
                  priceMaximumFractionDigits
                )}
                currentMinValue={commaSeparator(
                  nv?.range.length ? nv.range[0] : range[0],
                  priceMaximumFractionDigits
                )}
                rangeProps={{
                  defaultValue: range,
                  min: range[0],
                  max: range[1],
                  step: 1,
                  value: nv?.range,
                  onChange: handleSliders
                }}
                unitsLabel={filter.label || ''}
                w="full"
                {...{ sliderMaxPrice }}
              />
              {nv ? (
                <Button
                  aria-label={overrideText('Ta bort från sökning', overrides?.removeFilter)}
                  color="text.mild"
                  onClick={() => {
                    handleRemoveSlider(nv.id)
                  }}
                  px={1}
                  size="sm"
                  variant="ghost">
                  <HStack align="center" justify="center">
                    <AiOutlineCloseCircle />
                    <GrandText>
                      {overrideText('Ta bort från sökning', overrides?.removeFilter)}
                    </GrandText>
                  </HStack>
                </Button>
              ) : null}
            </VStack>
          )
        } else if (values.length === 1) {
          return (
            <GrandText p={4} textAlign="center" w="full">
              {overrideText('Unikt alternativ', overrides?.uniqueOption)}: {range[0]} {filter.label}
            </GrandText>
          )
        }
        return <>{overrideText('inga värden hittades', overrides?.notValuesFound)}</>
      }

      default:
        return null
    }
  }

  return <>{getItems()}</>
}

export default FilterItems

function Item({
  value,
  handleCheckBoxes,
  selectedCheckboxes,
  filter
}: {
  value: string | number
  handleCheckBoxes: (event: ChangeEvent<HTMLInputElement>) => void
  selectedCheckboxes: { id: string; values: string[] }[]
  filter: FilterOptionDTO
}) {
  return (
    <GridItem flex={1}>
      <HStack spacing={2} w="full">
        <CheckboxFilterItem
          id={filter.name}
          isChecked={Boolean(
            selectedCheckboxes
              .find((item) => item.id === filter.name)
              ?.values.includes(value.toString())
          )}
          label={filter.label || ''}
          name={value.toString()}
          onChange={handleCheckBoxes}
          {...{ value }}
        />
      </HStack>
    </GridItem>
  )
}
