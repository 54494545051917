'use client'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { HStack, Stack, VStack } from '@chakra-ui/react'
import { MdAdd } from 'react-icons/md'
import { GrandIconButton, GrandText, ImageBox, NumberSelector } from 'ui'
import { commaSeparator } from 'shared-utils'
import { getProductImages } from 'api'
import type { AddToCartPayload } from 'ecosystem'
import { overrideText } from 'ui/lib/overrides'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import { Currency } from 'ecosystem'
import { useUIGlobalsContext } from 'ui/lib/ui-globals/hooks'
import type { BaseCartItem } from '../../cart/components/CartItem/types'

export interface RelatedCartItemProps extends BaseCartItem {
  onAddToCart: (payload: AddToCartPayload) => void
  isEditable?: boolean
  isNew?: boolean
  isNotAvailable?: boolean
  isNotAvailableText?: string
}

export interface RelatedCartItemOverrides {
  addToCart?: string
  notInStock?: string
  currency?: string
}

const RelatedCartItem: FC<RelatedCartItemProps> = ({
  cartItem,
  onAddToCart,
  isNotAvailable,
  isNew,
  isNotAvailableText,
  isEditable
}) => {
  const overrides = useOverridesContext<keyof RelatedCartItemOverrides>()
  const { priceMaximumFractionDigits } = useUIGlobalsContext()
  const [qty, setQty] = useState('1')
  const { mainImageUrl } = getProductImages(cartItem.medias)

  useEffect(() => {
    setQty(cartItem.qty || '0')
  }, [cartItem.qty])

  const getSize = () => {
    const dict = {
      sm: {
        w: {
          base: 10,
          md: 12
        },
        h: {
          base: 10,
          md: 12
        }
      }
    }
    return dict.sm
  }

  return (
    <VStack spacing={4} w="100%">
      <Stack direction={{ base: 'column', sm: 'row' }} w="100%">
        <GrandText
          flex={1}
          fontSize={{
            base: 'xs',
            md: 'sm'
          }}
          isTruncated
          w={{
            base: '100%',
            sm: 'auto'
          }}>
          {cartItem.name}
        </GrandText>

        <HStack spacing={4} w="full">
          <ImageBox
            alignSelf="flex-start"
            imageProps={{
              alt: cartItem.name,
              title: cartItem.name,
              src: mainImageUrl,
              style: {
                objectFit: 'contain'
              },
              sizes: '7em'
            }}
            {...getSize()}
          />
          <VStack flex={1} w="full">
            <HStack
              justifyContent={{
                base: 'flex-end',
                sm: 'initial'
              }}
              w="100%">
              <GrandText display={{ base: 'none', sm: 'initial' }} flexGrow={1} w="auto">
                {cartItem.name}
              </GrandText>

              {isNew && isNotAvailable ? (
                <GrandText justifySelf="flex-end" w="auto">
                  {isNotAvailableText || overrideText('Ej i lager', overrides?.notInStock)}
                </GrandText>
              ) : (
                <HStack align="center">
                  <GrandText
                    flexGrow={1}
                    fontSize={{
                      base: 'xs',
                      md: 'sm'
                    }}
                    fontWeight="medium"
                    textAlign="center">
                    {cartItem.price
                      ? commaSeparator(cartItem.price, priceMaximumFractionDigits)
                      : 0}{' '}
                    {overrideText(Currency.SEK, overrides?.currency)}
                  </GrandText>

                  {isEditable ? (
                    <NumberSelector
                      bg="background.default"
                      borderRadius="sm"
                      hiddenLabel="Antal"
                      id="cart-qty"
                      onChange={(e) => {
                        setQty(e)
                      }}
                      size="sm"
                      value={qty}
                      {...(isNew && { min: 0 })}
                    />
                  ) : (
                    <GrandText fontSize="xs" w="auto">
                      {qty}x
                    </GrandText>
                  )}

                  {isEditable && isNew ? (
                    <GrandIconButton
                      aria-label={overrideText('Lägg till i varukorg', overrides?.addToCart)}
                      bg="status.success"
                      color="text.light"
                      fontSize="xs"
                      icon={<MdAdd />}
                      isDisabled={parseInt(qty) === 0}
                      onClick={() => {
                        onAddToCart({ product: cartItem, qty })
                        setQty('0')
                      }}
                      shadow="none"
                      size="xxs"
                      tooltip={overrideText('Lägg till i varukorg', overrides?.addToCart)}
                    />
                  ) : null}
                </HStack>
              )}
            </HStack>
          </VStack>
        </HStack>
      </Stack>
    </VStack>
  )
}

export default RelatedCartItem
