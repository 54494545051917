import { ProductLightDTO } from 'ecosystem'
import { useModal } from '#/src/custom/controllers'
import { ModalContactProps } from '#/src/custom/controllers/modals/ModalContact'

const useProductRequest = () => {
  const { openModal } = useModal()

  const handleProductRequest = (product: ProductLightDTO) => {
    openModal<ModalContactProps>({
      title: product.name,
      componentId: 'contact',
      size: 'full',
      extraArgs: {
        product,
        containerProps: {},
        customDescriptionPlaceholder: 'Välj tillval'
      }
    })
  }

  return {
    handleProductRequest
  }
}

export default useProductRequest
