'use client'
import type { FC } from 'react'
import { useState } from 'react'
import type { IGrandSection } from 'ui'
import { Carousel, GrandSection } from 'ui'
import type { DeliveryDTO, ProductLightDTO } from 'ecosystem'
import type { BoxProps } from '@chakra-ui/react'
import { type GrandSectionHeadingLink } from 'ui/storefront/layout/GrandSection'
import type { CarouselItemSize } from 'ui/common/Carousel/constants'
import ProductListItem from './ProductListItem'

interface IFeaturedProducts extends IGrandSection {
  items: ProductLightDTO[]
  onBuy: (item: ProductLightDTO) => void
  onProductRequest: (item: ProductLightDTO) => void
  productsUrlPath: string
  deliveryOptions: DeliveryDTO[] | null
  title?: string
  headingTag?: any
  itemContainerProps?: Omit<BoxProps, 'gap' | 'children'>
  headingLink?: GrandSectionHeadingLink
  carouselItemsByBreakpoint?: CarouselItemSize
}

const FeaturedProducts: FC<IFeaturedProducts> = ({
  deliveryOptions,
  title,
  items,
  headingTag,
  onBuy,
  onProductRequest,
  productsUrlPath,
  itemContainerProps,
  headingLink,
  carouselItemsByBreakpoint,
  ...props
}) => {
  const [activeItem, setActiveItem] = useState(0)

  return (
    <GrandSection {...{ title, headingTag, headingLink }} {...props}>
      <Carousel
        gap={20}
        {...{ activeItem, setActiveItem, itemContainerProps, carouselItemsByBreakpoint }}>
        {items.map((item) => (
          <ProductListItem
            showsPrice
            key={item.id}
            w="full"
            {...{ item, deliveryOptions, onBuy, onProductRequest, productsUrlPath }}
          />
        ))}
      </Carousel>
    </GrandSection>
  )
}

export default FeaturedProducts
